import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./header";
import Footer from './footer';
import JamesCollin from "../assets/img/jamescollin-guitar-wedding.jpg";
import lukeYoda from "../assets/img/cdetmer-halloween-luke-yoda.jpg";

const Section = ({ id, rowBgColor, children }) => {
  return (
    <Container fluid className="vh-md-100 my-5" as="section">
      <div
        className="d-flex flex-column align-items-center justify-content-center h-100"
        id={id}
      >
        <Row className={`vw-md-90 vw-lg-75 ${rowBgColor}`}>
          {children}
        </Row>
      </div>
    </Container>
  )
}

export default function Home() {
  return (
    <>
      <Header title="" />
      <Section id="developer" rowBgColor="bg-santa-fe-90">
        <Col xs={12} lg={6} xl={7} className="d-flex flex-column justify-content-center px-3 pb-2 px-md-5 pb-md-5" >
          <h2>#Developer</h2>
          <p className="fs-5">
            I have been developing websites for <strong>20+ years</strong>.
            That seems like a long time, but I still have much to
            <strong>learn</strong>. I <strong>love</strong> building things
            that people use every day - that <strong>make</strong> it easier
            for them to do more, and <strong>improve</strong> their lives.
          </p>
          <p className="fs-5">
            <strong
              ><a
                href="https://www.linkedin.com/in/chrisdetmer/"
                target="_blank"
                rel="noreferrer"
                >LinkedIn</a
              ></strong>
            | <strong><Link to="/resume">Resume</Link></strong>
          </p>
        </Col>
        <Col xs={12} lg={6} xl={5} className="code-block px-3 pb-4 px-md-5 pb-md-5">
          <p className="h3">{">_"}</p>
          <div className="d-flex align-items-center justify-content-between">
            <ul className="list flex-fill font-size-medium">
              <li className="list-item">#CSS</li>
              <li className="list-item">#HTML</li>
              <li className="list-item">#JavaScript</li>
            </ul>
            <ul className="list flex-fill">
              <li className="list-item">#MaterialUI</li>
              <li className="list-item">#NextJS</li>
              <li className="list-item">#React</li>
              <li className="list-item">#SASS</li>
              <li className="list-item">#Tailwind</li>
              <li className="list-item">#TypeScript</li>
              <li className="list-item">#Webpack</li>
              <li className="list-item">#Webflow</li>
              <li className="list-item">#WordPress</li>
            </ul>
          </div>
        </Col>
      </Section>
      <Section id="musician" rowBgColor="bg-bermuda-grey-90">
        <Col xs={12} md={6} lg={5} xl={4} className="px-0 d-flex align-items-center justify-content-center">
          <div
            className="img-box"
            style={{backgroundImage: `url(${JamesCollin})`}}>
          </div>
        </Col>
        <Col xs={12} md={6} lg={7} xl={8} className="d-flex flex-column justify-content-center px-3 pb-2 px-md-5 pb-md-5">
          <h2>#Musician</h2>
          <p className="fs-5">
            I have been writing, recording and performing {" "}
            <strong>music</strong> since I was 15. I have released 4 albums, {" "}
            <strong>composed</strong> scores for film and television, and
            toured the U.S. and Europe. Above all, music is an {" "}
            <strong>expression</strong> of my inner self, that I
            occasionally feel comfortable {" "} <strong>sharing</strong> with the
            world.
          </p>
          <p className="fs-5">
            <strong
              ><a href="https://jamescollin.com/" target="_blank" rel="noreferrer"
                >James Collin</a
              ></strong
            >
          </p>
        </Col>
      </Section>
      <Section id="about" rowBgColor="bg-dingley-90">
        <Col xs={12} md={6} lg={7} xl={8} className="d-flex flex-column justify-content-center px-3 pb-2 px-md-5 pb-md-5">
          <h2>#About</h2>
          <ul className="list-inline font-size-regular font-weight-bold mb-3">
            <li className="list-inline-item"><span className="text-dark">#</span>Developer</li>
            <li className="list-inline-item"><span className="text-dark">#</span>Musician</li>
            <li className="list-inline-item"><span className="text-dark">#</span>Father</li>
            <li className="list-inline-item"><span className="text-dark">#</span>Human Being</li>
          </ul>
          <p className="fs-5">
            Each chapter of my life has brought incredible challenges and
            experiences. From these I hope I have retained something useful
            to carry me to the next adventure.
          </p>
          <p className="fs-5">
            I am a lifelong student of the world, and I never want to stop
            learning. I'm fascinated by the interaction of cultures,
            technology, and artistic expression.
          </p>
        </Col>
        <Col xs={12} md={6} lg={5} xl={4} className="px-0 d-flex align-items-center justify-content-center">
          <div
            className="img-box"
            style={{ backgroundImage: `url(${lukeYoda})` }}
          ></div>
        </Col>
      </Section>
      <Footer />
    </>
  )
}
