import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import Layout from "./layout";
import Home from "./components/home";
import Resume from "./components/resume";
import Work from "./components/work";
import FourOhFour from "./components/404";

function App() {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="resume" element={<Resume />} />
          <Route path="work" element={<Work />} />
          {/* <Route path="*" element={<FourOhFour />} /> */}
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default App;
